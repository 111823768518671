<template>
  <svg width="68" height="60" viewBox="0 0 68 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="square-root-variable-solid 1" clip-path="url(#clip0_732_499)">
<path id="Vector" d="M33.3625 9.15234C34.3069 5.95312 37.2583 3.75 40.6111 3.75H64.2222C66.3118 3.75 68 5.42578 68 7.5C68 9.57422 66.3118 11.25 64.2222 11.25H40.6111L28.1799 53.5547C27.7549 54.9961 26.5153 56.0391 25.016 56.2266C23.5167 56.4141 22.0528 55.6875 21.2972 54.3984L9.16111 33.75H3.77778C1.68819 33.75 0 32.0742 0 30C0 27.9258 1.68819 26.25 3.77778 26.25H9.16111C11.8528 26.25 14.3319 27.668 15.6896 29.9766L23.3868 43.0781L33.3625 9.15234ZM46.4431 27.3516C47.9188 25.8867 50.3153 25.8867 51.791 27.3516L56.6667 32.1914L61.5542 27.3398C63.0299 25.875 65.4264 25.875 66.9021 27.3398C68.3778 28.8047 68.3778 31.1836 66.9021 32.6484L62.0146 37.5L66.9021 42.3516C68.3778 43.8164 68.3778 46.1953 66.9021 47.6602C65.4264 49.125 63.0299 49.125 61.5542 47.6602L56.6667 42.8086L51.7792 47.6602C50.3035 49.125 47.9069 49.125 46.4313 47.6602C44.9556 46.1953 44.9556 43.8164 46.4313 42.3516L51.3188 37.5L46.4313 32.6484C44.9556 31.1836 44.9556 28.8047 46.4313 27.3398L46.4431 27.3516Z" fill="#1F53A1"/>
</g>
<defs>
<clipPath id="clip0_732_499">
<rect width="68" height="60" fill="white"/>
</clipPath>
</defs>
</svg>
</template>

<script>
export default {
    name: "SquareRootIcon"
}
</script>

<style scoped>

</style>