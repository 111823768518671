<template>
  <div class="footer-wrapper">
    <div class="content-wrapper">
      <div class="footer-content-wrapper">
        <div class="footer-links">
          <!-- routes für live ändern -->
          <router-link to="/impressum">Impressum</router-link>
          <router-link to="/data-protection">Datenschutz</router-link>
        </div>

        <div class="footer-social">
          <div>
            <a href="https://www.youtube.com/@mathepaul" target="_blank">
              <YoutubeIcon class="social-icon" />
            </a>
          </div>
          <div>
            <a href="https://www.instagram.com/mathepaul.at?igsh=MXZjYzBmZG9tZHo5dQ==" target="_blank">
              <InstagramIcon class="social-icon" />
            </a>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import YoutubeIcon from "./svg/YoutubeIcon"
import InstagramIcon from "./svg/InstagramIcon"

export default {
  name: "FooterSection",
  components: {
    YoutubeIcon,
    InstagramIcon,
  },
}
</script>

<style scoped>
.footer-wrapper {
  background: var(--primary-clr);
  padding: var(--xl-space) var(--s-space);
}

.footer-content-wrapper {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
}

.footer-links {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  width: 50%;
}

.footer-links a {
  display: inline-block;
  margin-top: var(--s-space);
  color: #fff;
}

.footer-social {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: var(--l-space);
}

.footer-social div {
  width: 60px;
  height: 60px;
}

.footer-social .social-icon {
  width: 100%;
  height: 100%;
  transform: scale(1);
  transition: transform 0.2s ease-out;
}

.footer-social .social-icon:hover {
  transform: scale(1.1);
}

@media (min-width: 1000px) {
  .footer-wrapper {
    padding: var(--xl-space);
  }

  .footer-content-wrapper {
    flex-direction: row;
  }

  .footer-links {
    flex-direction: row;
  }

  .footer-links a {
    margin-top: 0;
  }

  .footer-social {
    width: 50%;
    margin-bottom: 0;
  }

  .footer-social div {
    width: 80px;
    height: 80px;
  }
}
</style>