<template>
    <div class="content-wrapper" id="aboutMe">
        <div class="about-me-wrapper">
            <div class="about-me-img-wrapper">
                <div class="about-me-img"></div>
            </div>
            <div class="about-me-text">
                <h2>Mehr über mich</h2>
                <p>
                    Hallo, mein Name ist Paul und ich bin 30 Jahre alt. Ich habe an einer AHS in Graz maturiert und
                    Bauingenieurwissenschaften an der TU Graz studiert. <br /><br />Seit bereits 3 Jahren unterrichte ich
                    nun
                    Mathematik, Physik und Mechanik und konnte damit schon vielen Schülern und Studenten zu besseren
                    Noten verhelfen. Speziell bei der Vorbereitung auf die Matura konnte ich vielen Schülern
                    helfen, die Schule erfolgreich abzuschließen. <br /><br />Neben meiner Tätigkeit als Nachhilfelehrer,
                    verbringe ich
                    meine Zeit gerne draußen an der frischen Luft, sei es beim Tennis oder Badminton spielen, beim Laufen
                    oder
                    beim Wandern mit meiner Frau und unserem Hund. Es würde mich freuen, wenn ich auch dir weiterhelfen
                    könnte.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AboutMe",
}
</script>

<style scoped>
.about-me-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column-reverse;
    padding-bottom: var(--xl-space);
    padding-top: var(--s-space);
}

.about-me-img-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.about-me-img {
    width: 100%;
    height: 600px;
    background-image: url("../assets/about-me-img-aussortiert.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 32px;
}

.about-me-text {
    width: 100%;
}

.about-me-text h2 {
    font-size: var(--header-size);
    color: var(--primary-clr);
}

.about-me-text p {
    font-size: var(--normal-size);
    color: var(--text-clr);
}

@media (min-width: 1000px) {
    .about-me-wrapper {
        flex-direction: row;
        padding-top: var(--xl-space);
    }

    .about-me-img-wrapper {
        width: 40%;
        margin-right: 5%;
    }

    .about-me-text {
        width: 55%;
    }
}
</style>