<template>
    <div>
      <div id="calendar-button" class="custom-calendar-button"></div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CalendarButton',
    mounted() {
      // Laden Sie das CSS und das Skript für den Google Kalender Terminplanungs-Button
      const link = document.createElement('link');
      link.href = 'https://calendar.google.com/calendar/scheduling-button-script.css';
      link.rel = 'stylesheet';
      document.head.appendChild(link);
  
      const script = document.createElement('script');
      script.src = 'https://calendar.google.com/calendar/scheduling-button-script.js';
      script.async = true;
      script.onload = this.loadCalendarButton;
      document.body.appendChild(script);
    },
    methods: {
      loadCalendarButton() {
        const target = document.getElementById('calendar-button');
        window.calendar.schedulingButton.load({
          url: 'https://calendar.google.com/calendar/appointments/schedules/AcZssZ0tT7gsN6W1SkMowL56muhSt17bbjhL0PInBmiX_VaBrWuUQTzpo5yAndTaPDyjmIlq7SUcWPmO?gv=true',
          color: '#103061',
          label: '               Verfügbare Termine               ',
          target,
        });
      },
    },
  };
  </script>
  
  <style scoped>
  </style>