<template>
  <div class="prizing-wrapper" id="prizing">
    <div class="content-wrapper">
      <div class="prizing-header">
        <h2>Angebote & Preise</h2>
        <p>
          Hier findest du einen Überblick über die aktuellen Preise. Eine Einheit dauert 50 Minuten.<br>Für ein
          individuelles, auf dich zugeschnittenes Angebot, kannst du mich gerne jederzeit <a
            href="#contact">kontaktieren</a>.
        </p>
      </div>

      <div class="prizing-card-wrapper">
        <PrizingCard v-for="card in prizingCards" :key="card.title" :card="card" @request-modal="callModal($event)" />
      </div>
      <p class="whatsapp-explanation">* Whatsapp-Support: Die Support Zeiten sind von Montag bis Freitag von 09:00 - 18:00. In
        der Regel bekommst du in diesem Zeitraum innerhalb von 2 Stunden, spätestens aber am selben Tag, noch eine Antwort.
      </p>
    </div>
    <VueModal :show="showModal">
      <template #body>
        <RequestProductForm :products="prizingCardsShort" :selectedProduct="selectedProduct" @close="showModal = false" />
      </template>
    </VueModal>
    <div class="ellipse-background">
      <img src="../assets/prizing-ellipse-1.png" alt="ellipse">
      <img src="../assets/prizing-ellipse-2.png" alt="ellipse">
      <img src="../assets/prizing-ellipse-3.png" alt="ellipse">
      <img src="../assets/prizing-ellipse-4.png" alt="ellipse">
    </div>
  </div>
</template>

<script>
import PrizingCard from "./PrizingCard"
import VueModal from "./VueModal"
import RequestProductForm from "./RequestProductForm"
import { prizingCardData } from "../seed.js"

export default {
  name: "PrizingSection",
  components: {
    PrizingCard,
    RequestProductForm,
    VueModal,
  },

  data() {
    return {
      selectedProduct: "",
      showModal: false,
    }
  },

  computed: {
    prizingCards() {
      return prizingCardData.filter((prizingCard) => prizingCard.card )
    },

    prizingCardsShort() {
      return prizingCardData.map((card) => {
        return {
          title: card.title,
          prize: card.prize[0]
        }
      })
    }
  },

  methods: {
    callModal(productTitle) {
      this.showModal = true
      this.selectedProduct = productTitle
    }
  }
}

</script>

<style scoped>
.prizing-wrapper {
  position: relative;
  background: var(--primary-clr6);
  overflow: hidden;
}

.prizing-header {
  text-align: center;
}

.prizing-header h2 {
  font-size: var(--medium-header-size);
  color: var(--primary-clr);
}

.prizing-header p {
  font-size: var(--normal-size);
  color: var(--text-clr);
}

.prizing-header a {
  color: var(--primary-clr2);
}

.prizing-card-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  gap: 35px 0;
  padding: var(--l-space) 0 var(--xl-space) 0;
}

.ellipse-background img {
  position: absolute;
}

.ellipse-background img:nth-child(1) {
  left: 0;
  top: 25%;
}

.ellipse-background img:nth-child(2) {
  left: 15%;
  bottom: 0;
}

.ellipse-background img:nth-child(3) {
  bottom: 0;
  right: 0;
}

.ellipse-background img:nth-child(4) {
  top: 0%;
  right: 0;
}

.whatsapp-explanation {
font-size: var(--small-size);
  /* font-weight: 700; */
  color: var(--primary-clr7);
  padding-bottom: var(--l-space);
}

@media (min-width: 600px) {
  .prizing-card-wrapper {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 20px 20px;
  }
}

@media (min-width: 1140px) {
  .prizing-wrapper {
    padding-top: 50px;
  }

  .prizing-header {
    padding-top: var(--xs-space);
  }

  .prizing-card-wrapper {
    gap: 50px 35px;
  }
}
</style>