<template>
  <svg width="80" height="64" viewBox="0 0 80 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="infinity-solid 1">
<path id="Vector" d="M0 30.1375C0 20.125 8.125 12 18.1375 12C22.95 12 27.5625 13.9125 30.9625 17.3125L40 26.3375L49.025 17.3125C52.4375 13.9125 57.05 12 61.8625 12C71.875 12 80 20.125 80 30.1375V33.85C80 43.875 71.875 52 61.8625 52C57.05 52 52.4375 50.0875 49.0375 46.6875L40 37.6625L30.975 46.6875C27.5625 50.0875 22.95 52 18.1375 52C8.125 52 0 43.875 0 33.8625V30.1375ZM34.3375 32L25.3125 22.975C23.4125 21.075 20.825 20 18.1375 20C12.5375 20 8 24.5375 8 30.1375V33.85C8 39.45 12.5375 43.9875 18.1375 43.9875C20.825 43.9875 23.4125 42.925 25.3125 41.0125L34.3375 32ZM45.65 32L54.675 41.025C56.575 42.925 59.1625 44 61.85 44C67.45 44 71.9875 39.4625 71.9875 33.8625V30.1375C71.9875 24.5375 67.45 20 61.85 20C59.1625 20 56.575 21.0625 54.675 22.975L45.6625 32H45.65Z" fill="#1F53A1"/>
</g>
</svg>
</template>

<script>
export default {
    name: "InfinityIcon"
}
</script>

<style scoped>

</style>