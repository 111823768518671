import { createApp } from "vue";
import CookieConsentVue from "@/plugins/CookieConsentVue";
import router from "@/router";
import App from "./App.vue";

const app = createApp(App);
app.use(router);
app.use(CookieConsentVue, {
  guiOptions: {
    consentModal: {
      position: "middle center",
    }
  },

  categories: {
    necessary: {
      enabled: true, // this category is enabled by default
      readOnly: true, // this category cannot be disabled
    },
    analytics: {
        autoClear: {
            cookies: [
                {
                    name: /^_ga/,
                }
            ]
        }
    },
  },

  language: {
    default: "de",
    translations: {
      de: {
        consentModal: {
          title: "Wir verwenden Cookies",
          description: "Wir nutzen auf unserer Website verschiedene Cookies: Einige sind essenziell für die Funktionalität, andere helfen uns dabei, die Nutzerdaten besser zu verstehen. Sie sind also eine Hilfe, unsere Leistungen stetig zu optimieren und das Resultat ist ein deutlich besseres Erlebnis bei der Nutzung dieser Website. Einige Cookies, sofern zugestimmt, nutzen anonymisierte personenbezogene Daten - unsere Website funktioniert aber auch ohne.",
          acceptAllBtn: "Alle akzeptieren",
          acceptNecessaryBtn: "Nur notwendige Cookies akzeptieren",
          showPreferencesBtn: "individuelle Auswahl verwalten",
        },
        preferencesModal: {
          title: "Cookies verwalten",
          acceptAllBtn: "Alle akzeptieren",
          acceptNecessaryBtn: "Nur notwendige Cookies akzeptieren",
          savePreferencesBtn: "Aktuelle Auswahl akzeptieren",
          closeIconLabel: "Close modal",
          sections: [
            {
              // title: "Hat jemand... Cookies gesagt?",
              // description: "Ich möchte einen!",
            },
            {
              title: "Absolut notwendige Cookies",
              description:
                "Diese Cookies sind notwendig für die korrekte Funktion unserer Website und können nicht deaktiviert werden.",

              //this field will generate a toggle linked to the 'necessary' category
              linkedCategory: "necessary",
            },
            {
              title: "Performance und Analytics",
              description:
                "Diese Cookies sammeln Informationen über dein Nutzungsverhalten auf unserer Webite. Alle Daten sind anonymisiert und können nicht dazu benutzt werden, dich zu identifizieren.",
              linkedCategory: "analytics",
            },
            {
              title: "Mehr Information",
              description:
                'Für weitere Fragen in Bezug auf unsere Cookie-Richtlinien kannst du mich gerne <a href="#contact">kontaktieren</a>',
            },
          ],
        },
      },
    },
  },
});

app.mount("#app");
