<template>
  <div class="content-wrapper">
    <div class="hero-wrapper">
      <div class="hero-content">
        <h1>Nachhilfe, die den <br />Unterschied macht</h1>
        <p>
          Du bist auf der Suche nach einer passenden Nachhilfe in Mathematik, <br />
          Physik oder Mechanik? Dann bist du hier genau richtig
        </p>
        <!-- <div class="hero-content-button">
          <a @click="callModal($event)">Jetzt kostenlose Probestunde sichern</a>
        </div> -->
        <div class="calendar-btn-wrapper"><CalendarButton /></div>
      </div>

      <VueModal :show="showModal">
        <template #body>
          <RequestProductForm :products="prizingCardsShort" :selectedProduct="selectedProduct"
            @close="showModal = false" />
        </template>
      </VueModal>

      <div class="hero-img">
        <div class="hero-img-container"></div>
        <div class="hero-img-background"></div>
      </div>
    </div>
    <div class="advantages-wrapper">
      <div class="advantage-card">
        <div class="icon-wrapper">
          <InfinityIcon />
        </div>
        <h3>Flexibel</h3>
        <p>
          Persönliche Nachhilfe online - von jedem Ort aus möglich. Mit Zoom gemütlich von zu Hause an der Einheit
          teilnehmen.
        </p>
      </div>

      <div class="advantage-card">
        <div class="icon-wrapper">
          <PiIcon />
        </div>
        <h3>Individuell</h3>
        <p>
          Egal ob langfristige Begleitung oder Vorbereitung auf Prüfungen. Wir machen uns aus, wie dir die Nachhilfe
          am Besten passt.
        </p>
      </div>

      <div class="advantage-card">
        <div class="icon-wrapper">
          <SquareRootIcon />
        </div>
        <h3>Persönlich</h3>
        <p>
          In Einzelstunden oder Kleingruppen herausfinden, wie viel Spaß Mathematik machen kann. In den monatlichen
          Paketen ist auch ein WhatsApp Support inbegriffen.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import CalendarButton from "./CalendarButton"
import PiIcon from "./svg/PiIcon.vue"
import InfinityIcon from "./svg/InfinityIcon.vue"
import SquareRootIcon from "./svg/SquareRootIcon.vue"
import VueModal from "./VueModal.vue"
import RequestProductForm from "./RequestProductForm.vue"
import { prizingCardData } from "../seed.js"

export default {
  name: "HeroSection",
  components: {
    CalendarButton,
    VueModal,
    RequestProductForm,
    PiIcon,
    InfinityIcon,
    SquareRootIcon,
  },

  data() {
    return {
      showModal: false,
      selectedProduct: "Kostenlose Probestunde",
    }
  },

  computed: {
    prizingCardsShort() {
      return prizingCardData.map((card) => {
        return {
          title: card.title,
          prize: card.prize[0]
        }
      })
    },
  },

  methods: {
    callModal(e) {
      e.preventDefault()
      this.showModal = true
    },
  },
}
</script>

<style scoped>
.hero-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin: 60px 0 150px 0;
}

.hero-content {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  margin-bottom: var(--xl-space);
}

.hero-content h1 {
  font-size: var(--header-size);
  color: var(--primary-clr);
  margin-bottom: 0;
}

.hero-content p {
  font-size: var(--normal-size);
  color: var(--text-clr);
}

.hero-content-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--l-space);
}

.hero-content-button a {
  font-size: 22px;
  text-align: center;
  background: var(--primary-clr3);
  color: #fff;
  font-weight: 700;
  text-decoration: none;
  border-radius: 16px;
  border: none;
  padding: 16px 40px;
  cursor: pointer;
  transition: all .2s ease-out;
}

.hero-content-button a:hover {
  background: var(--primary-clr2);
}

.calendar-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-img {
  position: relative;
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;
}

.hero-img-container {
  position: relative;
  width: 80%;
  height: 100%;
  background-image: url("../assets/hero-img.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 32px;
  z-index: 2;
}

.hero-img-background {
  position: absolute;
  bottom: -12.5%;
  left: 5%;
  width: 80%;
  height: 100%;
  background: #E8F1FF;
  border-radius: 32px;
  z-index: 1;
}

.advantages-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 0;
}

.advantage-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: var(--l-space);
}

.advantage-card .icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 148px;
  height: 148px;
  background: var(--primary-clr6);
  border-radius: 32px;
}

.advantage-card h3 {
  font-size: var(--normal-size);
  font-weight: 700;
  color: var(--primary-clr);
}

.advantage-card p {
  font-size: 20px;
  color: var(--text-clr);
  text-align: center;
}

@media (min-width: 500px) {
  .hero-img {
    width: 100%;
    height: 500px;
    justify-content: center;
    margin-right: 30px;
  }

  .hero-img-container {
    width: 50%;
    margin-left: 50px;
  }

  .hero-img-background {
    left: 20%;
    width: 50%;
  }
}

@media (min-width: 1000px) {
  .hero-wrapper {
    flex-direction: row;
    margin: 180px 0 150px 0;
  }

  .hero-content {
    width: 60%;
    margin-bottom: 0;
  }

  .hero-content h1 {
    margin-bottom: var(--m-space);
  }

  .hero-content-button a {
    font-size: 18px;
  }

  .calendar-btn-wrapper {
    width: auto;
  }

  .hero-img {
    width: 40%;
    height: 450px;
    justify-content: center;
    margin-right: 0;
  }

  .hero-img-container {
    width: 50%;
    min-width: 255px;
    margin-left: 0;
  }

  .hero-img-background {
    left: 12.5%;
    width: 50%;
  }

  .advantages-wrapper {
    flex-direction: row;
    margin-bottom: 120px;
  }

  .advantage-card {
    width: 30%;
    margin-bottom: 0;
  }
}
</style>