<template>
    <div>
        <div class="product-form-wrapper">
            <h3>Nachhilfe anfragen</h3>
            <p>Bitte wähle dein gewünschtes Paket und gib deine Daten ein, um mir eine Anfrage zu senden.</p>
            <form id="product-form" method="post">
                <div class="product-form-row">
                    <div class="product-form-section">
                        <label for="product">Produkt</label>
                        <select name="product" id="product" v-model="product">
                            <option v-for="product in products" :key="product.title" :value="product.title"
                                :selected="isSelected(product.title)">
                                {{ product.title }}
                            </option>
                        </select>
                    </div>
                    <transition name="fade">
                        <div class="product-form-section" v-if="isProductDetails">
                            <label for="productDetails">Gewünschte Stundenanzahl</label>
                            <input type="text" name="productDetails" id="productDetails" v-model="productDetails"
                                @keydown.enter="onPressEnter($event, 0)">
                        </div>
                    </transition>
                </div>

                <div class="product-form-row">
                    <div class="product-form-section">
                        <label for="firstName">Vorname</label>
                        <input type="text" name="firstName" id="firstName" v-model="firstName"
                            :class="errorInputClasses('firstName')" ref="input1" @keydown.enter="onPressEnter($event, 1)">
                    </div>
                    <div class="product-form-section">
                        <label for="lastName">Nachname</label>
                        <input type="text" name="lastName" id="lastName" v-model="lastName"
                            :class="errorInputClasses('lastName')" ref="input2" @keydown.enter="onPressEnter($event, 2)">
                    </div>
                </div>

                <div class="product-form-row">
                    <div class="product-form-section">
                        <label for="phone">Telefonnummer</label>
                        <input type="text" name="phone" id="phone" v-model="phone" :class="errorInputClasses('phone')"
                            ref="input3" @keydown.enter="onPressEnter($event, 3)">
                    </div>
                    <div class="product-form-section">
                        <label for="email">Email</label>
                        <input type="email" name="email" id="email" v-model="email" :class="errorInputClasses('email')"
                            ref="input4" @keydown.enter="onPressEnter($event, 4)">
                    </div>
                </div>

                <div class="product-form-row">
                    <div class="product-form-section">
                        <label for="street">Straße</label>
                        <input type="text" name="street" id="street" v-model="street" :class="errorInputClasses('street')"
                            ref="input5" @keydown.enter="onPressEnter($event, 5)">
                    </div>
                    <div class="product-form-section">
                        <label for="streetnbr">Hausnummer</label>
                        <input type="text" name="streetnbr" id="streetnbr" v-model="streetnbr" :class="errorInputClasses('streetnbr')"
                            ref="input6" @keydown.enter="onPressEnter($event, 6)">
                    </div>
                </div>

                <div class="product-form-row">
                    <div class="product-form-section">
                        <label for="plz">PLZ</label>
                        <input type="text" name="plz" id="plz" v-model="plz" :class="errorInputClasses('plz')"
                            ref="input7" @keydown.enter="onPressEnter($event, 7)">
                    </div>
                    <div class="product-form-section">
                        <label for="city">Ort</label>
                        <input type="text" name="city" id="city" v-model="city" :class="errorInputClasses('city')"
                            ref="input8" @keydown.enter="onPressEnter($event, 8)">
                    </div>
                </div>

                <div class="product-form-row">
                    <div class="product-form-section">
                        <label for="schoolLevel">Schulstufe</label>
                        <input type="text" name="schoolLevel" id="schoolLevel" v-model="schoolLevel"
                            :class="errorInputClasses('schoolLevel')" ref="input9" @keydown.enter="onPressEnter($event, 9)">
                    </div>
                    <div class="product-form-section">
                        <label for="schoolType">Schulform</label>
                        <input type="schoolType" name="schoolType" id="schoolType" v-model="schoolType"
                            :class="errorInputClasses('schoolType')" ref="input10" @keydown.enter="onPressEnter($event, 10)">
                    </div>
                </div>

                <div class="product-form-section textarea-section">
                    <label for="message">Nachricht</label>
                    <textarea name="message" id="message" v-model="message"></textarea>
                </div>
                <div class="checkbox-wrapper">
                    <input type="checkbox" id="agb" name="agb" v-model="agb" :class="errorInputClasses('agb')">
                    <label for="agb">Ich habe die
                        <router-link to="/mathepaul/data-protection" class="agb-link">Datenschutzerklärung</router-link>
                        gelesen und
                        akzeptiere sie hiermit</label>
                </div>
                <div>
                    <p class="error-text" v-if="error">{{ error }}</p>
                    <p class="success-text" v-if="success">{{ success }}</p>
                </div>
                <div class="button-group">
                    <button @click="closeModal($event)">Abbrechen</button>
                    <button @click="submitForm($event)">
                        <span v-if="!loading">Senden</span>
                        <span class="spinner" v-else></span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from "axios"

export default {
    name: "RequestProductForm",
    props: {
        products: Object,
        selectedProduct: String,
    },

    data() {
        return {
            product: this.selectedProduct,
            productDetails: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            street: "",
            streetnbr: "",
            plz: "",
            city: "",
            schoolLevel: "",
            schoolType: "",
            message: "",
            agb: false,
            loading: false,
            error: false,
            success: false,

        }
    },

    emits: {
        "close": () => {
            return true
        }
    },

    computed: {
        isProductDetails() {
            switch (this.product) {
                case "Einzeleinheit":
                    return ["€40", "€35", "€30"]
                case "Unterricht zu zweit":
                    return ["€30", "€28", "€25"]
                default:
                    return false
            }
        }
    },

    methods: {
        isSelected(title) {
            return this.selectedProduct === title
        },

        onPressEnter(e, inputIndex) {
            e.preventDefault()
            if (inputIndex >= 0 && inputIndex <= 9 ) {
                this.$refs[`input${inputIndex + 1}`].focus()
            } else {
                console.log("inputIndex doesnt match the input fields")
            }
        },

        errorInputClasses(name) {
            switch (name) {
                case "email":
                    if (this.error && this.email === "") {
                        return ["input-error"]
                    } else {
                        return ""
                    }
                case "firstName":
                    if (this.error && this.firstName === "") {
                        return ["input-error"]
                    } else {
                        return ""
                    }
                case "lastName":
                    if (this.error && this.lastName === "") {
                        return ["input-error"]
                    } else {
                        return ""
                    }
                case "street":
                    if (this.error && this.street === "") {
                        return ["input-error"]
                    } else {
                        return ""
                    }
                case "streetnbr":
                    if (this.error && this.streetnbr === "") {
                        return ["input-error"]
                    } else {
                        return ""
                    }
                case "plz":
                    if (this.error && this.plz === "") {
                        return ["input-error"]
                    } else {
                        return ""
                    }
                case "city":
                    if (this.error && this.city === "") {
                        return ["input-error"]
                    } else {
                        return ""
                    }
                case "schoolLevel":
                    if (this.error && this.schoolLevel === "") {
                        return ["input-error"]
                    } else {
                        return ""
                    }
                case "agb":
                    if (this.error && this.agb === false) {
                        return ["input-error"]
                    } else {
                        return ""
                    }
                default:
                    return ""
            }
        },

        closeModal(event) {
            event.preventDefault()
            this.$emit("close")
        },

        submitForm(event) {
            event.preventDefault()
            this.loading = true
            this.error = ""
            this.success = ""

            if (!this.email.match(/^\S+@\S+\.\S+$/)) {
                this.error = "Bitte gib eine gültige E-Mail Adresse an!"
                this.loading = false
            } else if (this.firstName === "") {
                this.error = "Bitte gib deinen Vornamen an!"
                this.loading = false
            } else if (this.lastName === "") {
                this.error = "Bitte gib deinen Nachnamen an!"
                this.loading = false
            } else if (this.phone === "") {
                this.error = "Bitte gib eine gültige Telefonnummer an!"
                this.loading = false
            } else if (this.street === "") {
                this.error = "Bitte gib einen gültigen Straßennamen an!"
                this.loading = false
            } else if (this.streetnbr === "") {
                this.error = "Bitte gib eine Hausnummer an!"
                this.loading = false
            } else if (this.plz === "") {
                this.error = "Bitte gib eine Postleitzahl an!"
                this.loading = false
            } else if (this.city === "") {
                this.error = "Bitte gib einen Ort an!"
                this.loading = false
            } else if (this.schoolType === "") {
                this.error = "Bitte gib deine Schulform an!"
                this.loading = false
            } else if (this.schoolLevel === "") {
                this.error = "Bitte gib deine Schulstufe an!"
                this.loading = false
            } else if (this.agb === false) {
                this.error = "Du musst unsere Datenschutzerklärung akzeptieren!"
                this.loading = false
            } else {

                const data = new FormData(document.getElementById("product-form"))
                data.append("product", this.product)
                data.append("productDetails", this.productDetails)
                data.append("firstName", this.firstName)
                data.append("lastName", this.lastName)
                data.append("email", this.email)
                data.append("phone", this.phone)
                data.append("street", this.street)
                data.append("streetnbr", this.streetnbr)
                data.append("plz", this.plz)
                data.append("city", this.city)
                data.append("schoolLevel", this.schoolLevel)
                data.append("schoolType", this.schoolType)
                data.append("message", this.message)
                
                axios.post("https://mathepaul.at/api/api.php", data).then(res => {
                    if (res.data === "email success") {
                        console.log(res.data)
                        this.loading = false
                        this.success = "Die Anfrage wurde erfolgreich gesendet!"

                        this.firstName = ""
                        this.lastName = ""
                        this.email = ""
                        this.phone = ""
                        this.street = ""
                        this.streetnbr = ""
                        this.plz = ""
                        this.city = ""
                        this.schoolLevel = ""
                        this.schoolType = ""
                        this.message = ""
                        this.agb = false
                    } else {
                        console.log("Error: ", res.data)
                        this.loading = false
                        this.error = "Entschuldigung, hier ist leider etwas schief gegangen. Bitte versche es später erneut!"
                    }
                }).catch(err => {
                    console.log("Error", err)
                    this.loading = false
                    this.error = "Entschuldigung, hier ist leider etwas schief gegangen. Bitte verusche es später erneut!"
                })


            }
        }
    },
}

</script>

<style scoped>
.product-form-wrapper h3 {
    font-size: var(--small-header-size);
    color: var(--primary-clr);
    text-align: center;
    margin-bottom: var(--s-space);
}

.product-form-wrapper p {
    font-size: var(--small-size);
    color: var(--text-size);
    text-align: center;
    margin-top: 0;
}

.product-form-wrapper form {
    height: 350px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: var(--l-space);
}

.product-form-wrapper form::-webkit-scrollbar {
    width: 8px;
}

.product-form-wrapper form::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f1f1f1;
    border-radius: 10px;
}

.product-form-wrapper form::-webkit-scrollbar-thumb {
    background: var(--primary-clr2);
    border-radius: 10px;
    cursor: pointer;
}

.product-form-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;
}

.product-form-section {
    width: 100%;
    margin-top: var(--m-space);
}

.product-form-section.textarea-section {
    width: auto;
}

.product-form-wrapper label {
    display: block;
    font-size: var(--small-size);
    font-weight: 700;
    color: var(--primary-clr);
    margin-bottom: 5px;
}

.product-form-wrapper .checkbox-wrapper label {
    display: inline-block;
    font-size: var(--xs-size);
    color: var(--text-clr);
    margin: 0;
}

.product-form-wrapper select {
    display: block;
    width: calc(100% - 15px);
    font-size: 14px;
    color: var(--primary-clr7);
    padding: var(--xs-space) var(--s-space);
    border: 1px solid var(--text-clr);
    border-radius: 6px;
}

.product-form-wrapper select:focus {
    border: 1px solid var(--primary-clr2);
    outline: 1px solid var(--primary-clr2);
}

.product-form-wrapper input {
    display: block;
    width: calc(100% - 50px);
    font-size: var(--xs-size);
    color: var(--primary-clr7);
    word-break: break-all;
    padding: var(--xs-space) var(--s-space);
    border-radius: 6px;
    border: 1px solid var(--text-clr);
}

.product-form-wrapper input[type="checkbox"] {
    position: relative;
    display: inline-block;
    width: 15px;
    height: 15px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12);
    margin-right: 10px;
}

.product-form-wrapper input[type="checkbox"]:focus {
    border: none;
    outline: none;
}

.product-form-wrapper input[type="checkbox"]:checked {
    background: var(--primary-clr);
}

.product-form-wrapper input:focus {
    outline: 1px solid var(--primary-clr2);
    border: 1px solid var(--primary-clr2);
}

.product-form-wrapper textarea {
    display: block;
    width: calc(100% - 50px);
    height: 100px;
    font-size: var(--xs-size);
    color: var(--primary-clr7);
    word-break: break-all;
    resize: none;
    padding: var(--xs-space) var(--s-space);
    border-radius: 6px;
    border: 1px solid var(--text-clr);
}

.product-form-wrapper textarea:focus {
    outline: 1px solid var(--primary-clr2);
    border: 1px solid var(--primary-clr2);
}

.checkbox-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: var(--m-space);
}

.checkbox-wrapper .agb-link {
    color: var(--primary-clr2);
}

.error-text {
    color: red !important;
    border: 1px solid red;
    border-radius: 12px;
    margin-top: 20px !important;
}

.success-text {
    color: green !important;
    border: 1px solid green;
    border-radius: 12px;
    margin-top: 20px !important;
}

.button-group {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    width: 100%;
    margin-top: var(--s-space);
}

.button-group button {
    font-size: var(--small-size);
    color: #fff;
    background: var(--primary-clr2);
    cursor: pointer;
    border: none;
    border-radius: 12px;
    padding: var(--s-space) var(--l-space);
    transition: all 0.2s ease-out;
}

.button-group button:nth-child(1) {
    background: #fff;
    color: var(--primary-clr2);
    border: 1px solid var(--primary-clr2);
}

.button-group button:nth-child(1):hover {
    background: var(--primary-clr2);
    color: #fff;
    border: 1px solid var(--primary-clr2);
}

.button-group button:nth-child(2) {
    width: 145px;
    margin-bottom: var(--m-space);
}

.input-error {
    border: 1px solid red !important;
}

.spinner {
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 3px solid rgba(255, 255, 255, .3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

@media (min-width: 500px) {
    .product-form-wrapper form {
        height: 400px;
    }

    .product-form-row {
        flex-direction: row;
    }

    .product-form-section {
        width: 45%;
    }

    .button-group {
        flex-direction: row;
        margin-top: var(--l-space);
    }

    .button-group button:nth-child(1) {
        margin-right: var(--l-space);
    }

    .button-group button:nth-child(2) {
        margin-bottom: 0;
    }
}

@media (min-width: 1000px) {
    .button-group {
        margin-top: var(--xl-space);
    }
}</style>