<template>
    <div class="header-wrapper" >
        <div class="logo-wrapper">
            <!-- route für live ändern -->
            <router-link to="/" class="logo">
                <img src="../assets/logo.png" alt="MathePaul Logo">
            </router-link>
        </div>
        <nav class="desktop-nav">
            <ul>
                <!-- routes für live ändern -->
                <li>
                    <router-link to="/#prizing">Preise</router-link>
                </li>
                <li>
                    <router-link to="/#aboutMe">Über mich</router-link>
                </li>
                <li>
                    <router-link to="/#contact">Kontakt</router-link>
                </li>
                <li>
                    <a 
                        href="https://www.google.com/search?sca_esv=553650bb8dd64e30&sca_upv=1&cs=1&sxsrf=ADLYWILqAeJXGycY7XizhEV9QoxYZEyAnA:1722440491199&q=MathePaul+Rezensionen&ludocid=6262451679046541403&ibp=gwp;0,7&hl=de-AT&sa=X&ved=2ahUKEwic1_7sztGHAxWBhf0HHdupE1MQ9fQKegQIDhAF&biw=360&bih=649&dpr=3#lkt=LocalPoiReviews&lpg=cid:CgIgAQ%3D%3D" 
                        target="_blank"
                    >Rezensionen</a>
                </li>
            </ul>
        </nav>
        <nav class="mobile-nav">
            <div class="hamburger-icon-wrapper" @click="hamburgerToggle()" ref="hamburgerIconWrapper">
                <div class="hamburger-icon" ref="hamburger"></div>
            </div>
            <div class="menu" ref="menu">
                <ul>
                    <li>
                        <router-link to="/#prizing" @click="hamburgerToggle()">Preise</router-link>
                    </li>
                    <li>
                        <router-link to="/#aboutMe" @click="hamburgerToggle()">Über mich</router-link>
                    </li>
                    <li>
                        <router-link to="/#contact" @click="hamburgerToggle()">Kontakt</router-link>
                    </li>
                    <li>
                        <a 
                            href="https://www.google.com/search?sca_esv=553650bb8dd64e30&sca_upv=1&cs=1&sxsrf=ADLYWILqAeJXGycY7XizhEV9QoxYZEyAnA:1722440491199&q=MathePaul+Rezensionen&ludocid=6262451679046541403&ibp=gwp;0,7&hl=de-AT&sa=X&ved=2ahUKEwic1_7sztGHAxWBhf0HHdupE1MQ9fQKegQIDhAF&biw=360&bih=649&dpr=3#lkt=LocalPoiReviews&lpg=cid:CgIgAQ%3D%3D" 
                            target="_blank"
                            >Rezensionen</a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</template>

<script>

export default {
    name: "GeneralHeader",

    computed: {
        navPositionLeft() {
            if (window.screen.width > 1700) {
                const leftValue = (window.screen.width - 1700) / 2
                return `left: ${leftValue}px;`
            } else {
                return "left: 0;"
            }
        }
    },

    methods: {
        hamburgerToggle() {
            this.$refs.hamburgerIconWrapper.classList.toggle("active")
            this.$refs.hamburger.classList.toggle("active")
            this.$refs.menu.classList.toggle("active")
        }
    }
}
</script>

<style scoped>
.header-wrapper {
    position: fixed;
    top: 0;
    width: calc(100% - 40px);
    max-width: 1500px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 3;
    background: #ffffff;
    box-shadow: -2px -3px 5px 7px rgba(0, 0, 0, 0.16);
    padding: 0 20px;
    margin: 0 auto;
}

.logo {
    font-size: var(--medium-header-size);
    font-weight: bold;
    color: var(--primary-clr2);
    text-decoration: none;
    transition: all 0.2s ease-out;
}

.logo:hover {
    color: var(--primary-clr3);
}

.desktop-nav {
    display: none;
    pointer-events: none;
}

.mobile-nav {
    position: relative;
}

.hamburger-icon-wrapper {
    position: relative;
    width: 30px;
    height: 20px;
}

.hamburger-icon-wrapper::before {
    content: "2";
    position: absolute;
    top: -10px;
    right: -6px;
    font-size: 14px;
    font-weight: 700;
    color: var(--primary-clr3);
    opacity: 0;
    transform: translateY(-50%);
    transition: all 0.3s ease-out;
}

.hamburger-icon-wrapper.active::before {
    opacity: 1;
    transform: translateY(0);
}

.hamburger-icon {
    position: absolute;
    top: 13px;
    left: 0;
    width: 100%;
    height: 4px;
    background: var(--primary-clr2);
    border-radius: 16px;
    transition: all 0.3s ease-out;
}

.hamburger-icon::before {
    content: "";
    position: absolute;
    top: -13px;
    left: 0;
    width: 100%;
    height: 4px;
    background: var(--primary-clr2);
    border-radius: 16px;
    transition: all 0.3s ease-out;
}

.hamburger-icon.active {
    transform: rotate(45deg);
}

.hamburger-icon.active::before {
    transform: rotate(90deg);
    top: 0;
    left: 0;
}

.mobile-nav .menu {
    position: fixed;
    z-index: 5;
    top: 56px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(60% - 56px);
    background: #fff;
    box-shadow: -2px 7px 10px 0px rgba(0, 0, 0, 0.16);
    opacity: 0;
    transform: translateX(50%);
    pointer-events: none;
    transition: all 0.3s ease-out;
}

.mobile-nav .menu.active {
    opacity: 1;
    transform: translateX(0);
    pointer-events: all;
}

.mobile-nav .menu ul {
    list-style-type: none;
}

.mobile-nav .menu ul li {
    margin-bottom: var(--xl-space);
}

.mobile-nav .menu ul li a {
    font-size: var(--small-header-size);
    font-weight: bold;
    color: var(--primary-clr);
    text-decoration: none;
}

@media (min-width: 1000px) {
    .header-wrapper {
        position: fixed;
        top: 0;
        /* left: 0; */
        width: calc(100% - 200px);
        max-width: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 3;
        background: #ffffff;
        box-shadow: -2px -3px 5px 7px rgba(0, 0, 0, 0.16);
        padding: 0 100px;
        margin: 0 auto;
    }

    .logo {
        font-size: var(--medium-header-size);
        font-weight: bold;
        color: var(--primary-clr2);
        text-decoration: none;
        transition: all 0.2s ease-out;
    }

    .logo:hover {
        color: var(--primary-clr3);
    }

    .desktop-nav {
        display: block;
        pointer-events: all;
    }

    .desktop-nav li {
        display: inline-block;
        margin-left: var(--xl-space);
    }

    .desktop-nav li a {
        font-size: var(--small-size);
        color: var(--primary-clr7);
        text-decoration: none;
        transition: all 0.2s ease-out;
    }

    .desktop-nav li a:hover {
        color: var(--primary-clr3);
    }

    .mobile-nav {
        display: none;
        pointer-events: none;
    }
}
</style>