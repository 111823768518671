<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',

}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,400;8..144,700&display=swap');

:root {
  /* Colors */
  --primary-clr: #103061;
  --primary-clr2: #1F53A1;
  --primary-clr3: #4680D6;
  --primary-clr4: #7CA8E9;
  --primary-clr5: #ADCBF8;
  --primary-clr6: #F3F8FF;
  --primary-clr7: #010F25;
  --text-clr: #6B6C6F;

  /* Spacing */

  --xs-space: 8px;
  --s-space: 16px;
  --m-space: 24px;
  --l-space: 40px;
  --xl-space: 56px;

  /* Font */
  --normal-size: 20px;
  --small-size: 16px;
  --xs-size: 14px;
  --header-size: 48px;
  --medium-header-size: 48px;
  --small-header-size: 30px;
}

html,
body {
  position: relative;
  width: 100%;
  font-family: "Roboto Flex", sans-serif;
  font-size: 20px;
  overflow-x: hidden;
  scroll-behavior: smooth;
  margin: 0 auto;
}

p {
  line-height: 35px;
}

.page-wrapper {
  position: relative;
  overflow-x: hidden;
  max-width: 1700px;
  margin: 0 auto;
}

.content-wrapper {
  position: relative;
  z-index: 2;
  margin: 0 20px;
  max-width: 1700px;
}

/* Cookie Banner Styles */
#cc-main {
  color: var(--primary-clr7);
}

#cc-main .cm__desc {
  color: var(--text-clr);
}

#cc-main .cm__btn {
  background: var(--primary-clr);
  border: var(--primary-clr);
}

#cc-main .cm__btn:hover {
  background: var(--primary-clr2);
}

#cc-main .cm__btn--secondary {
  background: var(--primary-clr6);
}

#cc-main .cm__btn--secondary:hover {
  background: var(--primary-clr5);
}

#cc-main .pm__close-btn {
  background: var(--primary-clr6);
}

#cc-main .pm__close-btn:hover {
  background: var(--primary-clr5);
}

#cc-main .pm__section--toggle .pm__section-title {
  background: var(--primary-clr6);
}

#cc-main .pm__section--toggle .pm__section-title:hover {
  background: var(--primary-clr5);
}

#cc-main .pm__section--expandable .pm__section-arrow {
  background: var(--primary-clr6);
}

#cc-main .section__toggle:checked:disabled~.toggle__icon {
  background: var(--primary-clr);
}

#cc-main .section__toggle:checked~.toggle__icon {
  background: var(--primary-clr);
  box-shadow: 0 0 0 1px var(--primary-clr);
}

#cc-main .toggle__icon {
  background: var(--primary-clr6);
  box-shadow: 0 0 0 1px var(--primary-clr);
}

#cc-main .toggle__icon-on svg {
  stroke: var(--primary-clr);
}

#cc-main .toggle__icon-off svg {
  stroke: var(--primary-clr2);
}

#cc-main .pm__btn {
  background: var(--primary-clr);
  border: var(--primary-clr);
}

#cc-main .pm__btn:hover {
  background: var(--primary-clr2);
}

#cc-main .pm__btn--secondary {
  background: var(--primary-clr6);
}

#cc-main .pm__btn--secondary:hover {
  background: var(--primary-clr5);
}

/* Transitions */

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-50%);
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
  transform: translateY(0);
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease-out;
}

@media (min-width: 1000px) {
  :root {
    /* Colors */
    /* Spacing */
    /* Font */
    --normal-size: 24px;
    --small-size: 20px;
    --xs-size: 16px;
    --header-size: 64px;
    --medium-header-size: 48px;
    --small-header-size: 30px;
  }

  html,
  body {
    font-size: 24px;
  }

  p {
    line-height: 40px;
  }

  .content-wrapper {
    margin: 0 100px;
  }
}</style>
