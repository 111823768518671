<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="action / 5 - action, check, checkmark, ok icon">
<path id="Shape" d="M4 10.5712L10.2222 18L20 6" stroke="#4680D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</svg>
</template>

<script>
export default {
    name: "CheckmarkIcon"
}
</script>

<style scoped>

</style>