<template>
  <div>
    <GeneralHeader />
    <div class="page-wrapper">
      <HeroSection />
      <PrizingSection />
      <AboutMe />
      <ContactSection />
      <FooterSection />
    </div>
  </div>
</template>

<script>
import GeneralHeader from "../components/GeneralHeader"
import HeroSection from "../components/HeroSection"
import PrizingSection from "../components/PrizingSection"
import AboutMe from "../components/AboutMe"
import ContactSection from "../components/ContactSection"
import FooterSection from "../components/FooterSection"

export default {
  name: "HomePage",
  components: {
    GeneralHeader,
    HeroSection,
    PrizingSection,
    AboutMe,
    ContactSection,
    FooterSection,
  },
}
</script>

<style scoped></style>