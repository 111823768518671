<template>
  <div>
    <GeneralHeader />
    <div class="page-wrapper">
      <div class="impressum-wrapper content-wrapper">
        <h2>Impressum</h2>
        <p class="text-center"><b class="sub-header">Webseitenbetreiber:</b> Paul Teuschler BSc</p>
        <p class="text-center"><b class="sub-header">Anschrift:<br></b> Autaler Straße, 19b, 3/1<br> 8042 Graz</p>
        <p class="text-center"><b>Umsatzsteuerbefreit gem § 6 Abs 1 Z 27 UStG
            Gerichtsstand: Landesgericht Graz </b> <br> <b class="sub-header">Gewerbeaufsichtbehörde:</b> Landesgericht
          Graz
        </p>
        <p class="text-center"><b class="sub-header">Kontaktdaten:</b> <br> Telefon: +436641048221 <br> Email:
          paul.teuschler@gmx.at</p>

        <p class="text-center"><b class="sub-header">Anwendbare Rechtsvorschrift:</b> www.ris.bka.gv.at <br> <b
            class="sub-header">Berufsbezeichnung:</b> Nachhilfelehrer</p>
        <p><b class="sub-header">Online Streitbeilegung:</b> Verbraucher, welche in Österreich oder in einem sonstigen
          Vertragsstaat der ODR-VO
          niedergelassen sind, haben die Möglichkeit Probleme bezüglich dem entgeltlichen Kauf von Waren oder
          Dienstleistungen
          im Rahmen einer Online-Streitbeilegung (nach OS, AStG) zu lösen. Die Europäische Kommission stellt eine
          Plattform
          hierfür bereit: https://ec.europa.eu/consumers/odr</p>
        <p><b class="sub-header">Urheberrecht:</b> Die Inhalte dieser Webseite unterliegen, soweit dies rechtlich möglich
          ist, diversen
          Schutzrechten (z.B dem Urheberrecht). Jegliche Verwendung/Verbreitung von bereitgestelltem Material, welche
          urheberrechtlich untersagt ist, bedarf schriftlicher Zustimmung des Webseitenbetreibers.</p>
        <p><b class="sub-header">Haftungsausschluss:</b> Trotz sorgfältiger inhaltlicher Kontrolle übernimmt der
          Webseitenbetreiber dieser
          Webseite keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich
          deren Betreiber verantwortlich. Sollten Sie dennoch auf ausgehende Links aufmerksam werden, welche auf eine
          Webseite
          mit rechtswidriger Tätigkeit/Information verweisen, ersuchen wir um dementsprechenden Hinweis, um diese nach §
          17
          Abs. 2 ECG umgehend zu entfernen.<br>Die Urheberrechte Dritter werden vom Betreiber dieser Webseite mit größter
          Sorgfalt beachtet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden derartiger Rechtsverletzungen werden wir den betroffenen Inhalt
          umgehend
          entfernen.</p>
        <p>Quelle: <b><a href="https://www.rechtstexte-generator.at/dsgvo-generator-oesterreich/">Datenschutz DSGVO
              Generator</a></b> in Kooperation mit <b><a
              href="https://www.ortner-rechtsanwalt.at/rechtsanwalt-eherecht-scheidungsrecht-obsorgerecht/">Rechtsanwalt
              Wien
              Scheidung</a></b>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import GeneralHeader from "../components/GeneralHeader"

export default {
  name: "ImpressumPage",
  components: {
    GeneralHeader
  }
}
</script>

<style scoped>
.impressum-wrapper {
  margin-top: 150px;
}

.impressum-wrapper h2 {
  font-size: var(--header-size);
  color: var(--primary-clr);
  text-align: center;
  margin-bottom: var(--xl-space);
}

.impressum-wrapper p {
  color: var(--text-clr);
}

.impressum-wrapper a {
  color: var(--primary-clr3);
  transition: all 0.2s ease-out;
}

.impressum-wrapper a:hover {
  color: var(--primary-clr2);
}

.text-center {
  text-align: center;
}

.sub-header {
  font-size: var(--normal-size);
  color: var(--primary-clr);
}

@media (min-width: 1000px) {

  .impressum-wrapper h2 {
    margin-bottom: 100px;
  }
}</style>