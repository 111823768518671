<template>
    <div>
        <slot>
            <div class="prizing-card">
                <div class="prizing-card-header">
                    <div class="card-title-section">
                        <div class="prizing-icon-wrapper">
                            <img :src="require(`../assets/${card.icon}`)" :alt="card.icon">
                        </div>
                        <div>
                            <h3>{{ card.title }}</h3>
                            <p class="card-text">{{ card.description }}</p>
                        </div>
                    </div>
                    <div>
                        <p class="card-prize">{{ card.prize[0] }}</p>
                    </div>
                </div>
                <div class="prizing-card-body">
                    <div class="card-benefits-wrapper">
                        <p v-for="benefit in card.benefits" :key="benefit.id" class="card-benefits">
                            <span>
                                <CheckMarkIcon />
                            </span>
                            <span>{{ benefit.title }}</span>
                        </p>
                    </div>
                    <div class="card-button-wrapper">
                        <button @click="requestModal()">Anfragen</button>
                    </div>
                </div>
            </div>
        </slot>
    </div>
</template>

<script>
import CheckMarkIcon from "./svg/CheckmarkIcon.vue"

export default {
    name: "PrizingCard",
    components: {
        CheckMarkIcon,
    },

    props: {
        card: Object,
    },

    emits: {
        "request-modal": (payload) => {
            if (payload === "") {
                console.warn("Das Formular muss über ein Produkt aufgerufen werden!")
                return false
            }
            return true
        }
    },

    methods: {
        requestModal() {
            this.$emit("request-modal", this.card.title)
        }
    }
}
</script>

<style scoped>
.prizing-card {
    position: relative;
    z-index: 2;
    height: 420px;
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 3px 1px 30px 1px rgba(0, 0, 0, 0.25);
    padding: 30px;
}

.prizing-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.card-title-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.prizing-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    padding: 12px;
    background: var(--primary-clr);
    border-radius: 14px;
    margin-right: var(--s-space);
}

.prizing-card h3 {
    font-size: var(--small-header-size);
    color: var(--primary-clr);
    text-align: center;
    margin: 0;
}

.prizing-card .card-text {
    font-size: var(--small-size);
    color: var(--text-clr);
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
}

.card-prize {
    font-size: 55px;
    color: var(--primary-clr2);
    text-align: left;
    margin: var(--m-space) 0 var(--m-space) 0;
}

.card-prize span {
    font-size: var(--normal-size);
    color: var(--text-clr);
}

.prizing-card-body {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.card-benefits {
    display: flex;
    align-items: center;
    color: var(--primary-clr7);
    text-align: left;
    margin-top: var(--xs-space);
    margin-bottom: 0;
}

.card-benefits span {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: var(--s-space);
}

.card-button-wrapper {
    position: absolute;
    bottom: 30px;
    right: 25%;
    display: flex;
    align-items: left;
    justify-content: flex-start;
    margin-top: var(--m-space);
}

.card-button-wrapper button {
    font-size: 18px;
    color: #fff;
    font-weight: bold;
    background: var(--primary-clr3);
    border-radius: 16px;
    border: none;
    padding: 18px 40px;
    cursor: pointer;
    transition: all .2s ease-out;
}

.card-button-wrapper button:hover {
    background: var(--primary-clr2);
}

@media (min-width: 1140px) {
    .prizing-card {
        height: 220px;
        padding: 40px;
        padding-bottom: 120px;
    }

    .prizing-card-header {
        flex-direction: row;
    }

    .prizing-icon-wrapper {
        margin-right: var(--m-space);
    }

    .prizing-card h3 {
        text-align: left;
    }

    .prizing-card .card-text {
        text-align: left;
    }

    .card-prize {
        font-size: 60px;
        margin: 0 0 var(--m-space) 0;
    }

    /* .card-benefits-wrapper {
        margin-bottom: 50px;
    } */

    .card-benefits {
        margin-top: var(--m-space);
    }
/* delete class below for desktop? */
    .card-benefits span {
        margin-right: var(--s-space);
    }

    .card-button-wrapper {
        bottom: 40px;
        right: 40px;
    }
}
</style>