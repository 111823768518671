<template>
    <div class="contact-wrapper" id="contact">
        <div class="content-wrapper">
            <div class="contact-content-wrapper">
                <h2>Du hast Fragen?<br>Kontaktiere mich gerne jederzeit</h2>
                <div class="contact-details-wrapper">
                    <p>
                        <img src="../assets/email-icon.png" alt="email-icon">
                        paul.teuschler@gmx.at
                    </p>
                    <p>
                        <img src="../assets/phone-icon.png" alt="phone-icon">
                        +43 664 10 48 221 (WhatsApp)
                    </p>
                </div>
            </div>
        </div>
        <div class="ellipse-background">
            <img src="../assets/ellipse-contact1.png" alt="ellipse">
            <img src="../assets/ellipse-contact2.png" alt="ellipse">
            <img src="../assets/ellipse-contact3.png" alt="ellipse">
            <img src="../assets/ellipse-contact4.png" alt="ellipse">
        </div>

        <!-- <GoogleRezensionen /> -->

    </div>
</template>

<script>
// import GoogleRezensionen from "./GoogleRezensionen"

export default {
    name: "ContactSection",
    components: {
        // GoogleRezensionen,
    },
}
</script>

<style scoped>
.contact-wrapper {
    position: relative;
    background: var(--primary-clr6);
    overflow: hidden;
    padding: 0 0 60px 0;
}

.contact-content-wrapper {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-content-wrapper h2 {
    font-size: var(--header-size);
    color: var(--primary-clr);
    text-align: center;
}

.contact-details-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.contact-details-wrapper p {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--normal-size);
    color: var(--primary-clr7);
}

.contact-details-wrapper p img {
    display: inline-block;
    margin-right: 10px;
}

.ellipse-background img {
    position: absolute;
}

.ellipse-background img:nth-child(1) {
    top: 0;
    left: 0;
}

.ellipse-background img:nth-child(2) {
    top: 40%;
    left: 0;
}

.ellipse-background img:nth-child(3) {
    bottom: 0;
    right: 0;
}

.ellipse-background img:nth-child(4) {
    top: -5%;
    right: 0;
}

@media (min-width: 1000px) {
    .contact-wrapper {
        padding: 60px 0;
    }

    .contact-details-wrapper {
        flex-direction: row;
    }
}
</style>