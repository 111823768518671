<template>
  <svg width="66" height="57" viewBox="0 0 66 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Layer_1" clip-path="url(#clip0_732_494)">
    <path id="Vector" d="M33.0025 0C23.7544 0 14.5064 0 5.25834 0.00954374C4.67146 0.00954374 4.07512 0.038175 3.50244 0.147928C1.37738 0.563081 -0.014091 2.40025 0.000107652 4.72415C0.0143063 7.04328 1.39631 9.00929 3.5687 9.19062C6.61667 9.44353 9.69304 9.42445 12.7552 9.40536C13.9006 9.39581 14.2271 9.73939 14.2177 10.8799C14.1751 17.8993 14.2366 24.9235 14.1703 31.9429C14.1514 34.1141 13.9195 36.2949 13.6545 38.4517C13.1433 42.5794 11.6714 46.4112 9.95809 50.1715C8.86006 52.5812 9.92969 55.5875 12.1163 56.6039C14.3123 57.6251 17.3319 56.6039 18.3164 54.2562C19.67 51.0208 20.9715 47.7378 21.9749 44.3784C23.2101 40.2603 23.4468 35.9608 23.4752 31.6757C23.5273 24.7326 23.5178 17.7895 23.461 10.8512C23.4515 9.67258 23.8254 9.40059 24.9376 9.41013C29.6989 9.46739 34.4602 9.43399 39.2262 9.43399C41.4444 9.43399 42.5534 10.5586 42.5534 12.8077C42.5676 23.8737 42.402 34.9444 42.6717 46.0056C42.8374 52.8389 49.5155 57.816 56.2409 56.895C60.5715 56.2985 63.3734 53.6311 65.3564 49.9376C66.5018 47.8094 65.8723 45.0894 64.0549 43.7294C62.3463 42.4553 59.7385 42.7178 58.0252 44.4261C57.4478 45.0035 57.036 45.7479 56.5391 46.4064C55.5688 47.6949 53.8697 48.0432 52.8569 46.917C52.2653 46.2585 51.8914 45.1562 51.8867 44.2496C51.8251 33.0213 51.8535 21.7979 51.8299 10.5697C51.8299 9.69167 52.0665 9.39581 52.961 9.4149C55.6777 9.46739 58.3944 9.46739 61.1063 9.42445C65.612 9.35287 66.8852 5.22043 65.4227 2.35253C64.5093 0.553537 62.8906 0 60.988 0C51.6595 0.00954374 42.331 0.00477187 33.0072 0H33.0025Z" fill="white"/>
    <path id="Vector_2" d="M33.0025 0C42.331 0.00477187 51.6595 0.00954374 60.988 0C62.8953 0 64.5092 0.553537 65.4274 2.34776C66.8899 5.21088 65.6167 9.34332 61.111 9.41967C58.3944 9.46262 55.6777 9.46262 52.9658 9.41013C52.0712 9.39104 51.8299 9.6869 51.8346 10.5649C51.8583 21.7931 51.8299 33.0166 51.8914 44.2448C51.8961 45.1514 52.27 46.2537 52.8616 46.9123C53.8745 48.0384 55.5736 47.6948 56.5438 46.4017C57.0408 45.7432 57.4478 44.994 58.0299 44.4213C59.7432 42.7178 62.3511 42.4506 64.0596 43.7247C65.8818 45.0846 66.5065 47.8094 65.3612 49.9329C63.3734 53.6263 60.5762 56.2938 56.2456 56.8902C49.5202 57.816 42.8421 52.8389 42.6765 46.0008C42.4114 34.9396 42.5724 23.8689 42.5582 12.8029C42.5582 10.5538 41.4491 9.42922 39.2309 9.42922C34.4697 9.42922 29.7084 9.46262 24.9424 9.40536C23.8302 9.39104 23.461 9.66781 23.4657 10.8465C23.5178 17.7895 23.5272 24.7326 23.4799 31.6709C23.4468 35.956 23.2101 40.2555 21.9796 44.3736C20.9715 47.733 19.6747 51.0161 18.3211 54.2514C17.3414 56.5944 14.3218 57.6203 12.121 56.5992C9.93442 55.5827 8.86479 52.5765 9.96282 50.1667C11.6761 46.4064 13.148 42.5746 13.6592 38.447C13.929 36.2901 14.1561 34.1093 14.1751 31.9381C14.2413 24.9187 14.1751 17.8945 14.2224 10.8751C14.2319 9.73462 13.9053 9.39104 12.7599 9.40059C9.69778 9.42445 6.62141 9.43876 3.57343 9.18585C1.39631 9.00929 0.0143063 7.04328 0.000107653 4.72415C-0.014091 2.40025 1.37738 0.563081 3.4977 0.147928C4.07512 0.038175 4.67146 0.00954374 5.25834 0.00954374C14.5064 0 23.7544 0 33.0025 0Z" fill="#1F53A1"/>
    </g>
    <defs>
    <clipPath id="clip0_732_494">
    <rect width="66" height="57" fill="white"/>
    </clipPath>
    </defs>
    </svg>
</template>

<script>
export default {
    name: "PiIcon"
}
</script>

<style scoped>

</style>