export const prizingCardData = [
  {
    icon: "single-lesson-icon.png",
    description: "Preis pro Person/Stunde",
    title: "Einzeleinheit",
    card: true,
    prize: ["€30", "pro Stunde"],
    benefits: [
      {
        id: 0,
        title: "ab 5 Stunden €28",
      },
      {
        id: 1,
        title: "ab 10 Stunden €25",
      },
    ],
  },
  {
    icon: "double-lesson-icon.png",
    title: "Unterricht zu zweit",
    description: "Preis pro Person/Stunde",
    card: true,
    prize: ["€25", "pro Stunde"],
    benefits: [
      {
        id: 2,
        title: "ab 5 Stunden €23",
      },
      {
        id: 3,
        title: "ab 10 Stunden €20",
      },
    ],
  },
  {
    icon: "month-regular-icon.png",
    title: "Monatliches Paket Regular",
    description: "",
    card: true,
    prize: ["€120", "pro Monat"],
    benefits: [
      {
        id: 6,
        title: "4 Einheiten pro Monat"
      },
      {
        id: 7,
        title: "inklusive WhatsApp-Support *",
      },
    ],
  },
  {
    icon: "month-large-icon.png",
    title: "Monatliches Paket Large",
    description: "",
    card: true,
    prize: ["€200", "pro Monat"],
    benefits: [
      {
        id: 8,
        title: "8 Einheiten pro Monat"
      },
      {
        id: 9,
        title: "inklusive WhatsApp-Support *",
      },
    ],
  },
  {
    icon: "matura-icon.png",
    title: "Matura Intensivkurs",
    description: "April/Mai",
    card: true,
    prize: ["€400", "pro Kurs"],
    benefits: [
      {
        id: 10,
        title: "20 Einheiten in Kleingruppen",
      },
      {
        id: 11,
        title: "inklusive WhatsApp-Support *"
      }
    ],
  },
  // {
  //   icon: "",
  //   title: "Kostenlose Probestunde",
  //   description: "",
  //   card: false,
  //   prize: ["€0"],
  //   benefits: [],
  // },
];
