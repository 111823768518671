import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../pages/HomePage.vue";
import ImpressumPage from "../pages/ImpressumPage.vue";
import DataProtectionPage from "../pages/DataProtectionPage.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: HomePage,
    },

    {
      path: "/impressum",
      component: ImpressumPage,
    },

    {
      path: "/data-protection",
      component: DataProtectionPage,
    },

    {
      path: "/:pathMatch(.*)*",
      component: HomePage,
    },
  ],
  scrollBehavior(to) {
    if (to.hash.match(/^#/)) {
      return {
        el: to.hash,
        top: 50,
      };
    } else {
      return { top: 0 };
    }
  },
});

export default router;
